import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet"
import "./header.scss"
import Switch from "../switch/switch"
import MartinHofmannLogo from "../icons/logos/martinHofmannLogo/martinHofmannLogo"
import GitHubLogo from "../icons/logos/gitHubLogo"
import LinkedInLogo from "../icons/logos/linkedInLogo"

const Header = ({ siteTitle, siteSubTitle }) => {

  const [theme, setTheme] = useState(() => {
    const themeValue = typeof window !== 'undefined' ? window.localStorage.getItem('themePreference') : null

    return themeValue !== null
      ? JSON.parse(themeValue)
      : 'dark'
  })

  useEffect(() => {
    const localValue = (typeof window !== 'undefined')
      ? window.localStorage.setItem('themePreference', JSON.stringify(theme))
      : null

    return localValue
  })

  return (
    <header className="mh-header">
      <Helmet
        htmlAttributes={{
          class: theme === 'dark' ? 'mh-theme--dark' : null
        }}
      />
      <div className="mh-header__grid">
        <div className="mh-header__cell">
          <Link
            className="mh-header__title-link"
            activeClassName="mh-header__title-link--is-active"
            to="/"
          >
            <MartinHofmannLogo className="mh-header__logo" />
            <div className="mh-header__title-lockup">
              <div className="mh-header__title">{siteTitle}</div>
              <div className="mh-header__subtitle">{siteSubTitle}</div>
            </div>
          </Link>
        </div>

        <div className="mh-header__cell mh-header__cell--social">
          <Switch
            onChange={(checked) => {setTheme(checked ? 'light' : 'dark')}}
            checked={theme === 'light'}
            label={theme === "light" ? "Light Mode" : "Dark Mode"}
            theme="dark"
            labelPlacement="left"
          />
          <a href="https://www.linkedin.com/in/martinhofmanndesign" className="mh-header__social-link">
            <LinkedInLogo className="mh-header__social-logo" />
          </a>
          <a href="https://github.com/Martskin" className="mh-header__social-link">
            <GitHubLogo className="mh-header__social-logo" />
          </a>
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  siteSubTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
  siteSubTitle: ``,
}

export default Header
