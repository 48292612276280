import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import "./code.scss"

const IconCode = ({className, variant}) => {
  
  let iconCodeClass = classNames({
    'mh-icon-code': true,
    [`mh-icon-code--${variant}`]: variant,
    [`${className}`] : className,
  })

  return (
    <svg
      aria-hidden="true"
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
      width="80px"
      height="80px"
      viewBox="0 0 80 80"
      style={{fill: 'currentColor'}}
      className={iconCodeClass}
    >
      <g>
        <path className="mh-icon-code__tag mh-icon-code__tag--r1-1" d="M22.223,8.354H10.43c-2.7,0-4.888,2.329-4.888,5.028s2.188,4.972,4.888,4.972h11.793c2.7,0,4.889-2.272,4.889-4.972
          S24.923,8.354,22.223,8.354z"/>
        <path className="mh-icon-code__tag mh-icon-code__tag--r1-2" d="M44.112,13.383c0-2.664-2.135-4.958-4.786-5.017c-2.65,0.06-4.784,2.353-4.784,5.017s2.134,4.902,4.784,4.961
          C41.977,18.285,44.112,16.047,44.112,13.383z"/>
      </g>
      <g>
        <path className="mh-icon-code__tag mh-icon-code__tag--r2-1" d="M42.223,26.354H20.43c-2.7,0-4.888,2.329-4.888,5.028s2.188,4.972,4.888,4.972h21.793c2.7,0,4.889-2.272,4.889-4.972
          S44.923,26.354,42.223,26.354z"/>
        <path className="mh-icon-code__tag mh-icon-code__tag--r2-2" d="M69.223,26.354H57.43c-2.7,0-4.888,2.329-4.888,5.028s2.188,4.972,4.888,4.972h11.793c2.7,0,4.89-2.272,4.89-4.972
          S71.923,26.354,69.223,26.354z"/>
      </g>
      <g>
        <path className="mh-icon-code__tag mh-icon-code__tag--r3-1" d="M34.223,44.354H20.43c-2.7,0-4.888,2.329-4.888,5.028s2.188,4.972,4.888,4.972h13.793c2.7,0,4.889-2.272,4.889-4.972
          S36.923,44.354,34.223,44.354z"/>
        <path className="mh-icon-code__tag mh-icon-code__tag--r3-2" d="M55.223,44.354H49.43c-2.7,0-4.888,2.329-4.888,5.028s2.188,4.972,4.888,4.972h5.793c2.7,0,4.889-2.272,4.889-4.972
          S57.923,44.354,55.223,44.354z"/>
      </g>
      <g>
        <path className="mh-icon-code__tag mh-icon-code__tag--r4-1" d="M10.223,62.354h0.207c-2.7,0-4.888,2.329-4.888,5.028s2.188,4.972,4.888,4.972h-0.207c2.7,0,4.889-2.272,4.889-4.972
          S12.923,62.354,10.223,62.354z"/>
      </g>
    </svg>
  )
}

export default IconCode

IconCode.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string
}

IconCode.defaultProps = {
  className: ``,
  variant: ``
}
