import React, { useState } from 'react'
import Proptypes from 'prop-types'
import _uniqueId from 'lodash/uniqueId';
import classNames from 'classnames'
import './switch.scss'

const Switch = ({
  label,
  labelPlacement,
  checked,
  disabled,
  onChange,
  variant,
  theme
}) => {
  let switchClassname = classNames({
    'mh-switch': true,
    [`mh-switch--${theme}`]: theme,
    [`mh-switch--${variant}`]: variant,
    [`mh-switch--label-${labelPlacement}`]: labelPlacement
  })

  const [id] = useState(_uniqueId('input-')); 

  return (
    <label
      className={switchClassname}
      disabled={disabled}
      onChange={onChange ? (e)=> onChange(e.target.checked) : null}
      htmlFor={id}
    >
      <input
        id={id}
        className="mh-switch__checkbox"
        type="checkbox"
        defaultChecked={checked}
      />
      <span className="mh-switch__icon" aria-hidden="true" />
      <span className="mh-switch__label">{label}</span>
    </label>
  )
}

export default Switch

Switch.protoTypes = {
  onChange: Proptypes.func,
  checked: Proptypes.bool,
  disabled: Proptypes.bool,
  label: Proptypes.string,
  labelPlacement: Proptypes.string,
  variant: Proptypes.string,
  theme: Proptypes.string
}

Switch.defaultProps = {
  onChange: ``,
  checked: false,
  disabled: false,
  label: ``,
  labelPlacement: ``,
  variant: ``,
  theme: ``
}
