import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import "./button.scss"

const Button = ({
    onClick,
    label,
    iconOnly,
    variant,
    element
  }) => {

  let buttonClass = classNames({
    'mh-button': true,
    'mh-button--icon-only': iconOnly,
    [`mh-button--${variant}`]: variant
  })

  const ElementType = element

  return(
    <ElementType
      className={buttonClass}
      onClick={onClick}
    >
      {label && (
        <span className="mh-button__label">{label}</span>
      )}
      {iconOnly}
    </ElementType>
  )
}

export default Button

Button.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string,
  iconOnly: PropTypes.node,
  element: PropTypes.string,
  variant: PropTypes.string
}

Button.defaultProps = {
  onClick: null,
  label: ``,
  iconOnly: ``,
  element: `button`,
  variant: ``
}
