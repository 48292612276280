import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import "./list.scss"

const IconList = ({className, variant}) => {
  
  let iconListClass = classNames({
    'mh-icon-list': true,
    [`mh-icon-list--${variant}`]: variant,
    [`${className}`] : className,
  })

  return (
    <svg
      aria-hidden="true"
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
      width="80px"
      height="80px"
      viewBox="0 0 80 80"
      style={{fill: 'currentColor'}}
      className={iconListClass}
    >
      <g className="mh-icon-list__bullets">
        <circle cx="11.009" cy="13" r="5.122"/>
        <circle cx="11.009" cy="31" r="5.122"/>
        <circle cx="11.009" cy="49" r="5.122"/>
        <circle cx="11.009" cy="67" r="5.122"/>
      </g>
      <g>
        <path className="mh-icon-list__item mh-icon-list__item-1" d="M69.223,8.354H25.43c-2.7,0-4.888,2.329-4.888,5.028s2.188,4.972,4.888,4.972h43.793c2.7,0,4.889-2.272,4.889-4.972
          S71.923,8.354,69.223,8.354z"/>
        <path className="mh-icon-list__item mh-icon-list__item-2" d="M69.223,26.354H25.43c-2.7,0-4.888,2.329-4.888,5.028s2.188,4.972,4.888,4.972h43.793c2.7,0,4.889-2.272,4.889-4.972
          S71.923,26.354,69.223,26.354z"/>
        <path className="mh-icon-list__item mh-icon-list__item-3" d="M69.223,44.354H25.43c-2.7,0-4.888,2.329-4.888,5.028s2.188,4.972,4.888,4.972h43.793c2.7,0,4.889-2.272,4.889-4.972
          S71.923,44.354,69.223,44.354z"/>
        <path className="mh-icon-list__item mh-icon-list__item-4" d="M69.223,62.354H25.43c-2.7,0-4.888,2.329-4.888,5.028s2.188,4.972,4.888,4.972h43.793c2.7,0,4.889-2.272,4.889-4.972
          S71.923,62.354,69.223,62.354z"/>
      </g>
    </svg>
  )
}

export default IconList

IconList.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string
}

IconList.defaultProps = {
  className: ``,
  variant: ``
}
