import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import "./navList.scss"

const NavList = ({heading, items, filter}) => {

  return (
    <ul className="mh-nav-list">
      {heading && (
        <li className="mh-nav-list__item mh-nav-list__item--heading">
          {heading.link
            ? (
              <Link
                className="mh-nav-list__nav-link"
                activeClassName="mh-nav-list__nav-link--is-active"
                to={heading.link}
              >
                <span className="mh-nav-list__nav-link-icon" aria-hidden="true">
                  {heading.icon}
                </span>
                <span className="mh-nav-list__nav-link-label">{heading.label}</span>
              </Link>
            )
            : heading.label
          }
        </li>
      )}
      {items.map(({node}) => {
        const filterKey = filter ? filter : heading.label.toLowerCase()

        return (
          node.frontmatter.category === filterKey && (
            <li
              className="mh-nav-list__item"
              key={node.id}
            >
              <Link
                className="mh-nav-list__link"
                activeClassName="mh-nav-list__link--is-active"
                to={node.fields.slug}
              >
                {node.frontmatter.title}
              </Link>
            </li>
          )
        )
      })}
    </ul>
  )
}

export default NavList

NavList.prototypes = {
  heading: PropTypes.object,
  items: PropTypes.array,
  filter: PropTypes.string
}

NavList.defaultProps = {
  heading: {}, //{label: 'Blog Posts', link: '/'}}
  items: [],
  filter: ``
}
