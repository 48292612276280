import React from "react"

const GitHubLogo = ({className}) => (
  <svg
    aria-labelledby="github-logo"
    xmlns="http://www.w3.org/2000/svg"
	  width="32px"
    height="32px"
    viewBox="0 0 32 32"
    style={{fill: 'currentColor'}}
    className={className}
  >
  <title id="github-logo">GitHub</title>
  <path d="M16,1.713c-8.088,0-14.648,6.559-14.648,14.649
    c0,6.472,4.197,11.963,10.019,13.899c0.732,0.134,1-0.317,1-0.706c0-0.348-0.013-1.271-0.02-2.493
    c-4.075,0.886-4.934-1.962-4.934-1.962C6.75,23.409,5.79,22.957,5.79,22.957c-1.33-0.909,0.101-0.892,0.101-0.892
    c1.47,0.105,2.243,1.512,2.243,1.512c1.307,2.237,3.429,1.591,4.263,1.217c0.133-0.946,0.512-1.594,0.93-1.958
    c-3.252-0.371-6.672-1.628-6.672-7.239c0-1.6,0.571-2.908,1.508-3.931c-0.15-0.371-0.654-1.86,0.144-3.877
    c0,0,1.229-0.394,4.028,1.501c1.168-0.325,2.422-0.487,3.668-0.493c1.244,0.005,2.497,0.168,3.667,0.493
    c2.797-1.896,4.023-1.501,4.023-1.501c0.8,2.017,0.296,3.506,0.146,3.877c0.938,1.023,1.505,2.332,1.505,3.931
    c0,5.627-3.426,6.864-6.688,7.227c0.527,0.454,0.995,1.348,0.995,2.715c0,1.957-0.019,3.537-0.019,4.018
    c0,0.393,0.264,0.847,1.008,0.704c5.817-1.94,10.009-7.427,10.009-13.897C30.648,8.272,24.09,1.713,16,1.713z"/>
  </svg>
)
export default GitHubLogo
