import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import "./root.scss"

const IconRoot = ({className, variant}) => {
  
  let iconRootClass = classNames({
    'mh-icon-root': true,
    [`mh-icon-root--${variant}`]: variant,
    [`${className}`] : className,
  })

  return (
    <svg
      aria-hidden="true"
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
      width="80px"
      height="80px"
      viewBox="0 0 80 80"
      style={{fill: 'currentColor'}}
      className={iconRootClass}
    >
      <g className="mh-root__dot mh-root__dot--1">
        <circle cx="44.937" cy="41.692" r="5.122"/>
      </g>
      <g className="mh-root__dot mh-root__dot--2">
        <circle cx="28.021" cy="41.692" r="5.122"/>
      </g>
      <g className="mh-root__dot mh-root__dot--3">
        <circle cx="11.104" cy="41.692" r="5.122"/>
      </g>
      <path className="mh-root__slash" d="M57.881,59.257l15.732-35.244c0.878-1.996-0.131-4.37-2.127-5.248c-1.996-0.878-4.386,0-5.264,1.996L50.49,56.006
        c-0.878,1.995,0.089,4.352,2.085,5.23C54.571,62.114,57.004,61.252,57.881,59.257z"/>
    </svg>
  )
}

export default IconRoot

IconRoot.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string
}

IconRoot.defaultProps = {
  className: ``,
  variant: ``
}
