/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState, useEffect } from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "../header/header"
import NavList from "../navList/navList"
import Button from "../button/button"
import IconList from "../icons/list/list"
import IconCode from "../icons/code/code"
import IconBranch from "../icons/branch/branch"
import IconRoot from "../icons/root/root"
import "./layout.scss"
import "../grid/grid.scss"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
    site {
      siteMetadata {
        title
        subtitle
      }
    }
    allMarkdownRemark(sort: {frontmatter: {date: DESC}}) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            category
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
  `)

  const [sideNavIsCollapsed, setSideNavIsCollapsed] = useState(() => {
    const sideNavValue = typeof window !== 'undefined' ? window.localStorage.getItem('sideNavIsCollapsed') : null

    return sideNavValue !== null
      ? JSON.parse(sideNavValue)
      : false
  })

  useEffect(() => {
    const localValue = (typeof window !== 'undefined')
      ? window.localStorage.setItem('sideNavIsCollapsed', JSON.stringify(sideNavIsCollapsed))
      : null

    return localValue
  })

  let layoutClass = classNames({
    'mh-layout': true,
    'mh-layout--side-nav-is-collapsed': sideNavIsCollapsed
  })

  function toggleMenu(e) {
    e.stopPropagation()
    setSideNavIsCollapsed(!sideNavIsCollapsed)
  }

  return (
    <div className={layoutClass}>
      <div className="mh-layout__header">
        <Header
          siteTitle={data.site.siteMetadata.title}
          siteSubTitle={data.site.siteMetadata.subtitle}
        />
      </div>
      <div className="mh-layout__col mh-layout__col--left">
        <div className="mh-layout__nav-toggle-button">
          <Button
            label="Toggle nav"
            variant="ghost"
            iconOnly={
              <IconList
                className={sideNavIsCollapsed ? 'mh-icon-list--is-collapsed' : null}
              />
            }
            onClick={(e) => toggleMenu(e)}
          />
        </div>
        <NavList
          heading={{
            label: 'Home',
            link: '/',
            icon: <IconRoot />
          }}
          items={data.allMarkdownRemark.edges}
          filter="about"
        />
        <NavList
          heading={{
            label: 'Blog',
            link: '/blog',
            icon: <IconCode />
          }}
          items={data.allMarkdownRemark.edges}
        />
        <NavList
          heading={{
            label: 'Portfolio',
            link: '/portfolio',
            icon: <IconBranch />
          }}
          items={data.allMarkdownRemark.edges}
        />
      </div>
      <div className="mh-layout__col mh-layout__col--main">
        <main className="mh-layout__main">{children}</main>
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
