import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import "./branch.scss"

const IconBranch = ({className, variant}) => {
  
  let iconBranchClass = classNames({
    'mh-icon-branch': true,
    [`mh-icon-branch--${variant}`]: variant,
    [`${className}`] : className,
  })

  return (
    <svg
      aria-hidden="true"
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
      width="80px"
      height="80px"
      viewBox="0 0 80 80"
      style={{fill: 'currentColor'}}
      className={iconBranchClass}
    >
      <path className="mh-icon-branch__line" d="M51.26,35.354H25.886c-5.518,0-6.563-2.073-6.563-5.899v-2.101v-3h-6v3v2.101v26.899h6V40.159
        c1.799,0.792,3.991,1.196,6.563,1.196H51.26c4.759,0,6.062,1.232,6.062,5.732v3.268h6v-3.268
        C63.322,39.302,59.264,35.354,51.26,35.354z"/>
      <path className="mh-icon-branch__point mh-icon-branch__point--1" d="M16.349,29.467c-5.687,0-10.313-4.627-10.313-10.314c0-5.687,4.626-10.313,10.313-10.313
        c5.687,0,10.314,4.626,10.314,10.313C26.663,24.84,22.036,29.467,16.349,29.467z M16.349,14.84c-2.378,0-4.313,1.935-4.313,4.313
        c0,2.379,1.935,4.314,4.313,4.314c2.379,0,4.314-1.935,4.314-4.314C20.663,16.774,18.728,14.84,16.349,14.84z"/>
      <path className="mh-icon-branch__point mh-icon-branch__point--2" d="M16.349,71.16c-5.687,0-10.313-4.627-10.313-10.315c0-5.686,4.626-10.312,10.313-10.312c5.687,0,10.314,4.626,10.314,10.312
        C26.663,66.533,22.036,71.16,16.349,71.16z M16.349,56.533c-2.378,0-4.313,1.935-4.313,4.312c0,2.379,1.935,4.315,4.313,4.315
        c2.379,0,4.314-1.936,4.314-4.315C20.663,58.468,18.728,56.533,16.349,56.533z"/>
      <path className="mh-icon-branch__point mh-icon-branch__point--3" d="M60.39,67.822c-5.687,0-10.312-4.627-10.312-10.315c0-5.686,4.626-10.312,10.312-10.312c5.688,0,10.314,4.625,10.314,10.312
        C70.704,63.194,66.077,67.822,60.39,67.822z M60.39,53.195c-2.378,0-4.312,1.934-4.312,4.312c0,2.379,1.935,4.315,4.312,4.315
        c2.379,0,4.314-1.936,4.314-4.315C64.704,55.129,62.769,53.195,60.39,53.195z"/>
    </svg>
  )
}

export default IconBranch

IconBranch.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string
}

IconBranch.defaultProps = {
  className: ``,
  variant: ``
}
